import React, { FC, memo } from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import CampiriCoverImage from '../../../public/static/compressedImages/homepage/campiri-bez-bav.jpg';
import { ArrowLink } from './styles';
import { routes } from '~/src/utils/routing';
import LocalizedLink from '~/src/components/LocalizedLink';

const HowToStartBlockCampiriCover: FC = () => (
  <EarnBox>
    <EarnBoxImageWrapper>
      <Image alt="Mountains" src={CampiriCoverImage} layout="fill" objectFit="cover" quality={70} />
    </EarnBoxImageWrapper>

    <EarnBoxContent>
      <EarnBoxPreHead>Půjčování bez obav</EarnBoxPreHead>
      <EarnBoxHead>
        Máte obavy z půjčení karavanu?
        <br />
        Jen na Campiri ke každé rezervaci:
      </EarnBoxHead>
      <EarnListBenefits>
        <EarnListBenefitsItem>
          <i className="icon-check" />
          Záruka přesnosti nabídky
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className="icon-check" />
          Garance vrácení peněz
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className="icon-check" />
          Záruka ochrany rezervace
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className="icon-check" />
          Linka péče o zákazníky
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className="icon icon-icon-money-hand" />
          Pojištění storna a poškození
        </EarnListBenefitsItem>
      </EarnListBenefits>
      <LocalizedLink href={routes.search} passHref={true}>
        <ArrowLink>
          chci na cestu bez obav
          <i className="icon-action-forward" />
        </ArrowLink>
      </LocalizedLink>
    </EarnBoxContent>
  </EarnBox>
);
const EarnBox = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  @media ${deviceMaxWidth.tablet} {
    height: auto;
    flex-direction: column;
  }
`;

const EarnBoxImageWrapper = styled.div`
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  width: 53.8461538%;
  max-width: 520px;
  position: relative;
  @media ${deviceMaxWidth.tablet} {
    max-width: inherit;
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    height: 176px;
  }
`;

const EarnBoxContent = styled.div`
  width: 100%;
  padding: 32px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background-color: var(--color-beige);
  @media ${deviceMaxWidth.tablet} {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    padding: 20px 12px 20px 12px;
  }
`;

const EarnBoxPreHead = styled.span`
  display: inline-block;
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: var(--color-orange);
  text-transform: uppercase;
  @media ${deviceMaxWidth.xs} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
  }
`;

const EarnBoxHead = styled.h3`
  margin: 0 0 20px 0;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1f2244;

  @media ${deviceMaxWidth.xs} {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }
`;

const EarnListBenefits = styled.ul`
  list-style: none;
  columns: 2;
  column-gap: 5px;
  margin-bottom: 18px;
  @media ${deviceMaxWidth.xs} {
    columns: 1;
    margin-bottom: 12px;
  }
`;

const EarnListBenefitsItem = styled.li`
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1f2244;
  display: flex;
  align-items: center;
  .icon-check,
  .icon-icon-money-hand {
    @media ${deviceMaxWidth.xs} {
      font-size: 1.2rem;
    }
    margin-right: 12px;
    &:before {
      color: #119383;
    }
  }
`;
HowToStartBlockCampiriCover.displayName = 'HowToStartBlockCampiriCover';
export default memo(HowToStartBlockCampiriCover);
